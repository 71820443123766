<template>
  <div>
    <el-dialog class="cus_dialog " title="就业详情" :visible.sync="dialogshow" :close-on-click-modal="false"
               @close="closeDialog" width="600px" height="90%">
      <div style="position: absolute; right: 20px; top: 50px;" v-if="$store.state.pmid != 1">
        <el-button type="primary" size="small" icon="el-icon-edit" v-if="$store.state.sysopened"
                   @click="modify">编辑
        </el-button>
      </div>
      <table class="dttable">
        <tr>
          <td class="tdheader">所属学校</td>
          <td>{{ ckdItem.platform }}</td>
        </tr>
        <tr>
          <td :class="['tdheader', {'form-required': edit}]">姓名</td>
          <td>
            <span v-if="!edit">{{ ckdItem.stu_name }}</span>
            <el-input v-if="edit" v-model="ckdItem.stu_name" :readonly="!$store.state.sysopened"></el-input>
          </td>
        </tr>
        <tr>
          <td :class="['tdheader', {'form-required': edit}]">性别</td>
          <td>

            <span v-if="!edit">{{ ckdItem.gender }}</span>
            <el-select v-if="edit" v-model="ckdItem.gender" :disabled="!$store.state.sysopened">
              <el-option label="男" value="男"></el-option>
              <el-option label="女" value="女"></el-option>
            </el-select>

          </td>

        </tr>
        <tr>
          <td :class="['tdheader', {'form-required': edit}]">年龄</td>
          <td>

            <span v-if="!edit">{{ ckdItem.age }}</span>
            <el-input v-if="edit" v-model="ckdItem.age" :readonly="!$store.state.sysopened"></el-input>

          </td>
        </tr>
        <tr>
          <td :class="['tdheader', {'form-required': edit}]">学制</td>
          <td>

            <span v-if="!edit">{{ ckdItem.xuezhi }}</span>
            <el-select v-if="edit" v-model="ckdItem.xuezhi" :disabled="!$store.state.sysopened">
              <el-option label="中职" value="中职"></el-option>
              <el-option label="高职" value="高职"></el-option>
            </el-select>

          </td>

        </tr>
        <tr>
          <td :class="['tdheader', {'form-required': edit}]">就业去向</td>
          <td>
            <span v-if="!edit">{{ ckdItem.jyqx }}</span>
            <el-select v-if="edit" v-model="ckdItem.jyqx" @change="changeJyqx" :disabled="!$store.state.sysopened">
              <el-option label="就业" value="就业"></el-option>
              <el-option label="升学" value="升学"></el-option>
              <el-option label="参军" value="参军"></el-option>
              <el-option label="出国" value="出国"></el-option>
              <el-option label="创业" value="创业"></el-option>
              <el-option label="待业" value="待业"></el-option>
              <el-option label="其他" value="其他"></el-option>

            </el-select>
          </td>
        </tr>
        <tr>
          <td :class="['tdheader', {'form-required': edit}]">专业代码</td>
          <td>

            <span v-if="!edit">{{ ckdItem.zydm }}</span>
            <el-input v-if="edit" v-model="ckdItem.zydm" :readonly="!$store.state.sysopened"></el-input>

          </td>
        </tr>
        <tr>
          <td :class="['tdheader', {'form-required': edit}]">专业名称</td>
          <td>

            <span v-if="!edit">{{ ckdItem.xibu_name }}</span>
            <el-input v-if="edit" v-model="ckdItem.xibu_name" :readonly="!$store.state.sysopened"></el-input>

          </td>
        </tr>

        <tr>
          <td :class="['tdheader', {'form-required': edit}]">专业类别</td>
          <td>

            <span v-if="!edit">{{ ckdItem.zhuanye_type }}</span>
            <el-select v-if="edit" v-model="ckdItem.zhuanye_type" :disabled="!$store.state.sysopened">
              <el-option v-for="majorType in majorTypes" :label="majorType" :value="majorType"></el-option>
            </el-select>
          </td>
        </tr>
        <!-- <tr>
            <td class="tdheader">资格证书</td>
            <td>

                <span v-if="!edit">{{ ckdItem.zhengshu }}</span>
                <el-input v-if="edit" v-model="ckdItem.zhengshu"></el-input>
            </td>


        </tr> -->
        <tr>
          <td :class="['tdheader', {'form-required': edit}]">就业是否满意</td>
          <td>
            <span v-if="!edit">{{ ckdItem.manyidu }}</span>
            <el-select v-if="edit" v-model="ckdItem.manyidu" :disabled="!$store.state.sysopened">
              <el-option label="非常满意" value="非常满意"></el-option>
              <el-option label="满意" value="满意"></el-option>
              <el-option label="比较满意" value="比较满意"></el-option>
              <el-option label="不满意" value="不满意"></el-option>
              <el-option label="无法评估" value="无法评估"></el-option>
            </el-select>
          </td>
        </tr>
        <tr>
          <td class="tdheader">最高职业技能等级/职业资格证</td>
          <td>
            <span v-if="!edit">{{ ckdItem.prize_skill }}</span>
            <el-input v-if="edit" v-model="ckdItem.prize_skill" :readonly="!$store.state.sysopened"></el-input>

          </td>
        </tr>
        <!-- <tr>
            <td class="tdheader">校级及以上奖励</td>
            <td>

                <span v-if="!edit">{{ ckdItem.prize_sch }}</span>
                <el-input v-if="edit" v-model="ckdItem.prize_sch"></el-input>

            </td>
        </tr> -->
        <!-- <tr>
            <td class="tdheader">担任学生干部情况</td>
            <td>

                <span v-if="!edit">{{ ckdItem.stu_name }}</span>
                <el-input v-if="edit" v-model="ckdItem.stu_name"></el-input>

            </td>
        </tr> -->
        <tbody class="w_100" v-if="isSx">
        <tr>
          <td :class="['tdheader', {'form-required': edit && ckdItem.jyqx == '升学'}]">学校名称</td>
          <td>
            <span v-if="!edit">{{ ckdItem.school_name }}</span>
            <el-input v-if="edit" v-model="ckdItem.school_name" :readonly="!$store.state.sysopened"></el-input>

          </td>
        </tr>
        <tr>
          <td :class="['tdheader', {'form-required': edit && ckdItem.jyqx == '升学'}]">学历</td>
          <td>

            <span v-if="!edit">{{ ckdItem.enter_type }}</span>

            <el-select v-if="edit" v-model="ckdItem.enter_type" :disabled="!$store.state.sysopened">

              <el-option label="本科" value="本科"></el-option>
              <el-option label="专科" value="专科"></el-option>

            </el-select>
          </td>

        </tr>
        </tbody>
        <tbody class="w_100" v-if="showEnter">
        <tr>
          <td :class="['tdheader', {'form-required': edit && isRequired}]">就业单位名称</td>
          <td>
            <span v-if="!edit">{{ ckdItem.enter_name }}</span>
            <el-input v-if="edit" v-model="ckdItem.enter_name" :readonly="!$store.state.sysopened"></el-input>

          </td>
        </tr>
        <tr>
          <td :class="['tdheader', {'form-required': edit && isRequired}]">单位性质</td>
          <td>
            <span v-if="!edit">{{ ckdItem.dwxz }}</span>
            <el-select v-if="edit" v-model="ckdItem.dwxz" :disabled="!$store.state.sysopened">
              <el-option label="国有企业" value="国有企业"></el-option>
              <el-option label="外资企业" value="外资企业"></el-option>
              <el-option label="合资企业" value="合资企业"></el-option>
              <el-option label="私营企业" value="私营企业"></el-option>
              <el-option label="事业单位" value="事业单位"></el-option>
              <el-option label="国家机关" value="国家机关"></el-option>
            </el-select>
          </td>

        </tr>
        <tr>
          <td :class="['tdheader', {'form-required': edit && isRequired}]">单位地址<br/>（省、市）</td>
          <td>
            <span v-if="!edit">{{ ckdItem.enter_addr }}</span>
            <el-input v-if="edit" v-model="ckdItem.enter_addr" :readonly="!$store.state.sysopened"></el-input>

          </td>
        </tr>
        <tr v-if="showEnter">
          <td :class="['tdheader', {'form-required': edit && isRequired}]">单位地点类别</td>
          <td>
            <span v-if="!edit">{{ ckdItem.didian }}</span>
            <el-select v-if="edit" v-model="ckdItem.didian" :disabled="!$store.state.sysopened">
              <el-option label="城区" value="城区"></el-option>
              <el-option label="镇区" value="镇区"></el-option>
              <el-option label="乡村" value="乡村"></el-option>
            </el-select>
          </td>
        </tr>
        <tr>
          <td :class="['tdheader', {'form-required': edit && isRequired}]">所属产业</td>
          <td>

            <span v-if="!edit">{{ ckdItem.chanye }}</span>
            <el-select v-if="edit" v-model="ckdItem.chanye" :disabled="!$store.state.sysopened">

              <el-option label="第一产业" value="第一产业"></el-option>
              <el-option label="第二产业" value="第二产业"></el-option>
              <el-option label="第三产业" value="第三产业"></el-option>

            </el-select>
          </td>

        </tr>
        <tr>
          <td :class="['tdheader', {'form-required': edit && isRequired}]">部门</td>
          <td>

            <span v-if="!edit">{{ ckdItem.stu_dept }}</span>
            <el-input v-if="edit" v-model="ckdItem.stu_dept" :readonly="!$store.state.sysopened"></el-input>

          </td>
        </tr>
        <tr>
          <td :class="['tdheader', {'form-required': edit && isRequired}]">岗位</td>
          <td>

            <span v-if="!edit">{{ ckdItem.stu_job }}</span>
            <el-input v-if="edit" v-model="ckdItem.stu_job" :readonly="!$store.state.sysopened"></el-input>
          </td>


        </tr>
        <tr>
          <td :class="['tdheader', {'form-required': edit && isRequired}]">是否对口就业</td>
          <td>
            <span v-if="!edit">{{ ckdItem.duikou }}</span>
            <el-select v-if="edit" v-model="ckdItem.duikou" :disabled="!$store.state.sysopened">
              <el-option label="是" value="是"></el-option>
              <el-option label="否" value="否"></el-option>
            </el-select>
          </td>
        </tr>
        <tr v-if="showEnter">
          <td :class="['tdheader', {'form-required': edit && isRequired}]">是否学校推荐</td>
          <td>
            <span v-if="!edit">{{ ckdItem.qudao }}</span>
            <el-select v-if="edit" v-model="ckdItem.qudao" :disabled="!$store.state.sysopened">
              <el-option label="是" value="是"></el-option>
              <el-option label="否" value="否"></el-option>
            </el-select>
          </td>
        </tr>
        <tr>
          <td :class="['tdheader', {'form-required': edit && isRequired}]">有否劳动合同</td>
          <td>
            <span v-if="!edit">{{ ckdItem.hetong }}</span>
            <el-select v-if="edit" v-model="ckdItem.hetong" :disabled="!$store.state.sysopened">
              <el-option label="未签合同" value="未签合同"></el-option>
              <el-option label="1年及以内" value="1年及以内"></el-option>
              <el-option label="1～2(含)年" value="1～2(含)年"></el-option>
              <el-option label="2～3(含)年" value="2～3(含)年"></el-option>
              <el-option label="3年以上" value="3年以上"></el-option>
            </el-select>
          </td>
        </tr>
        <tr>
          <td :class="['tdheader', {'form-required': edit && isRequired}]">有否三险五险</td>
          <td>
            <span v-if="!edit">{{ ckdItem.wuxian }}</span>
            <el-select v-if="edit" v-model="ckdItem.wuxian" :disabled="!$store.state.sysopened">
              <el-option label="没有社保" value="没有社保"></el-option>
              <el-option label="三险" value="三险"></el-option>
              <el-option label="五险" value="五险"></el-option>
            </el-select>
          </td>
        </tr>

        <tr>
          <td :class="['tdheader', {'form-required': edit && isRequired}]">有否公积金</td>
          <td>
            <span v-if="!edit">{{ ckdItem.gongjijin }}</span>
            <el-select v-if="edit" v-model="ckdItem.gongjijin" :disabled="!$store.state.sysopened">
              <el-option label="有" value="有"></el-option>
              <el-option label="无" value="无"></el-option>

            </el-select>
          </td>
        </tr>

        <tr>
          <td :class="['tdheader', {'form-required': edit && isRequired}]">平均月工资(元)</td>
          <td>

            <span v-if="!edit">{{ ckdItem.salary }}</span>
            <el-input v-if="edit" v-model="ckdItem.salary" :readonly="!$store.state.sysopened"></el-input>

          </td>
        </tr>
        </tbody>
        <tr>
          <td class="tdheader">家庭住址</td>
          <td>
            <span v-if="!edit">{{ ckdItem.jtzz }}</span>
            <el-input v-if="edit" v-model="ckdItem.jtzz" :readonly="!$store.state.sysopened"></el-input>
          </td>
        </tr>
        <tr v-if="showEnter">
          <td :class="['tdheader', {'form-required': edit && isRequired}]">就业地域</td>
          <td>
            <span v-if="!edit">{{ ckdItem.diyu }}</span>
            <el-select v-if="edit" v-model="ckdItem.diyu" :disabled="!$store.state.sysopened">
              <el-option label="本地" value="本地"></el-option>
              <el-option label="异地" value="异地"></el-option>
            </el-select>

          </td>
        </tr>
        <tr>
          <td :class="['tdheader', {'form-required': edit}]">本人电话</td>
          <td>
            <span v-if="!edit">{{ ckdItem.lxdh }}</span>
            <el-input v-if="edit" v-model="ckdItem.lxdh" :readonly="!$store.state.sysopened"></el-input>
          </td>
        </tr>
        <tr>
          <td :class="['tdheader', {'form-required': edit}]">家长电话</td>
          <td>
            <span v-if="!edit">{{ ckdItem.jz_phone }}</span>
            <el-input v-if="edit" v-model="ckdItem.jz_phone" :readonly="!$store.state.sysopened"></el-input>
          </td>
        </tr>
        <tr>
          <td class="tdheader">备注</td>
          <td>
            <span v-if="!edit">{{ ckdItem.bz }}</span>
            <el-input v-if="edit" v-model="ckdItem.bz" :readonly="!$store.state.sysopened"></el-input>
          </td>
        </tr>


        <!-- <tr> -->
        <!-- <td class="tdheader">证明文件</td> -->
        <!-- <td> -->
        <!--  -->
        <!-- <el-image v-if="ckdItem.file" style="width: 100px; height: 100px" :src="ckdItem.file" -->
        <!-- fit="fit"  :preview-src-list="[ckdItem.file]"></el-image> -->
        <!-- <span v-else>未上传</span> -->
        <!--  -->
        <!-- </td> -->
        <!-- </tr> -->
      </table>


      <div style="padding: 20px; text-align: center;">
        <el-button v-if="edit" type="warning" icon="el-icon-close" @click="handleCancel">取消</el-button>
        <el-button v-if="edit && $store.state.sysopened" type="primary" icon="el-icon-check" style="margin-left: 50px;"
                   @click="saveForm">保存
        </el-button>

      </div>


    </el-dialog>


  </div>
</template>

<script>

export default {
  props: ["id"],
  components: {},
  data() {
    return {
      dialogshow: true,

      DataList: [],
      ckdItem: {},
      edit: false,
      showEnter: true,
      isSx: false,
      majorTypes: [],
      isRequired: false,
    }
  },
  mounted() {

    this.getData()
    this.getMajorType()
  },
  methods: {
    getData() {
      this.$http.post("/api/jymingce_stuinfo", {id: this.id}).then(async res => {
        // res.data.bzr_phone = await this.decryptWord(res.data.bzr_phone)
        // res.data.jz_phone = await this.decryptWord(res.data.jz_phone)
        // res.data.lxdh = await this.decryptWord(res.data.lxdh)
        res.data.bzr_phone = '***'
        res.data.lxdh = '***'
        res.data.jz_phone = '***'
        this.ckdItem = res.data
        this.ckdItem = Object.assign({}, this.ckdItem, {school_name: ""})
        this.ckdItem.qudao = this.ckdItem.qudao == '学校推荐' ? '是' : this.ckdItem.qudao == '自联' ? '否' : this.ckdItem.qudao
        this.ckdItem.wuxian = this.ckdItem.wuxian.replace('一金','')
        if (this.ckdItem.quxiang == '升入高一级学校') {
          this.showEnter = false
          this.isSx = true
          this.ckdItem.school_name = this.ckdItem.enter_name
        } else {
          if (['参军', '待业'].indexOf(this.ckdItem.jyqx) >= 0) {
            this.showEnter = false
          } else {
            this.showEnter = true
          }
          this.isSx = false
        }
        if (this.ckdItem.jyqx == '就业' || this.ckdItem.jyqx == '创业') {
          this.isRequired = true
        }
      })
    },
    closeDialog() {
      this.dialogshow = false
      this.$$parent(this, "infoShow", false)
    },

    saveForm() {
      let formData = {...this.ckdItem}
      if (formData.school_name) {
        formData.enter_name = formData.school_name
        formData.dwxz = ""
        formData.chanye = ""
        formData.stu_job = ""
        formData.stu_dept = ""
        formData.duikou = ""
        formData.hetong = ""
        formData.wuxian = ""
        formData.gongjijin = ""
        formData.salary = ""
        formData.diyu = ""
        formData.didian = ""
        formData.qudao = ""
        formData.quxiang = ""
        formData.enter_addr = ""
        formData.quxiang = '升入高一级学校'
      }
      if (!formData.stu_name) {
        return this.$message.warning('姓名不能为空')
      }
      if (!formData.gender) {
        return this.$message.warning('请选择性别')
      }
      if (!formData.age) {
        return this.$message.warning('年龄不能为空')
      }
      if (!formData.xuezhi) {
        return this.$message.warning('请选择学制')
      }
      if (!formData.jyqx) {
        return this.$message.warning('请选择就业去向')
      }
      if (!formData.zydm) {
        return this.$message.warning('专业代码不能为空')
      }
      if (!formData.xibu_name) {
        return this.$message.warning('专业名称不能为空')
      }
      if (!formData.zhuanye_type) {
        return this.$message.warning('请选择专业类别')
      }
      if (!formData.manyidu) {
        return this.$message.warning('请选择就业满意度')
      }

      if (formData.jyqx == '升学') {
        if (!formData.school_name) {
          return this.$message.warning('学校名称不能为空')
        }
        if (!formData.enter_type) {
          return this.$message.warning('请选择学历')
        }
      } else if (formData.jyqx == '就业' || formData.jyqx == '创业') {
        if (!formData.enter_name) {
          return this.$message.warning('就业单位名称不能为空')
        }
        if (!formData.dwxz) {
          return this.$message.warning('请选择单位性质')
        }
        if (!formData.enter_addr) {
          return this.$message.warning('单位地址不能为空')
        }
        if (!formData.didian) {
          return this.$message.warning('请选择单位地点类别')
        }
        if (!formData.chanye) {
          return this.$message.warning('请选择所属产业')
        }
        if (!formData.stu_dept) {
          return this.$message.warning('部门不能为空')
        }
        if (!formData.stu_job) {
          return this.$message.warning('岗位不能为空')
        }
        if (!formData.duikou) {
          return this.$message.warning('请选择是否对口就业')
        }
        if (!formData.qudao) {
          return this.$message.warning('请选择是否学校推荐')
        }
        if (!formData.hetong) {
          return this.$message.warning('请选择劳动合同')
        }
        if (!formData.wuxian) {
          return this.$message.warning('请选择三险五险')
        }
        if (!formData.gongjijin) {
          return this.$message.warning('请选择公积金')
        }
        if (!formData.salary) {
          return this.$message.warning('请输入平均月工资')
        }
        if (!formData.diyu) {
          return this.$message.warning('请选择就业地域')
        }
      } else if (formData.jyqx == '出国') {
        if (formData.school_name && !formData.enter_type) {
          return this.$message.warning('请选择学历')
        }
        if (!formData.school_name && formData.enter_type) {
          return this.$message.warning('请输入学校名称')
        }
      }
      if (!formData.lxdh) {
        return this.$message.warning('本人电话不能为空')
      }
      if (!formData.jz_phone) {
        return this.$message.warning('家长电话不能为空')
      }

      formData.qudao = formData.qudao == '是' ? '学校推荐' : formData.qudao == '否' ? '自联' : formData.qudao
      if (formData.wuxian && formData.wuxian != "没有社保") {
        formData.wuxian = formData.gongjijin == '有' ? (formData.wuxian + '一金') : formData.wuxian
      }
      if (formData.enter_addr) {
        formData.diyu = formData.enter_addr.indexOf('南京') >= 0 ? '本地' : '异地'
      }

      this.$http.post("/api/jymc_stu_edit", formData).then(res => {
        this.$message.success("修改成功")
        this.closeDialog()
        this.$$parent(this, "getDataList")
      })


    },
    getMajorType() {
      this.$http.post('/api/getMajorType').then(res => {
        this.majorTypes = res.data.map(m => m.type_name)
      })
    },
    changeJyqx(value) {
      if (value == '升学' || value == '参军' || value == '待业') {
        this.showEnter = false
      } else {
        this.showEnter = true
      }
      if (value == '就业' || value == '创业') {
        this.isRequired = true
      } else {
        this.isRequired = false
      }
      this.isSx = value == '升学' || value == '出国'
      this.ckdItem.enter_name = ""
      this.ckdItem.enter_type = ""
      this.ckdItem.dwxz = ""
      this.ckdItem.chanye = ""
      this.ckdItem.stu_job = ""
      this.ckdItem.stu_dept = ""
      this.ckdItem.duikou = ""
      this.ckdItem.hetong = ""
      this.ckdItem.wuxian = ""
      this.ckdItem.gongjijin = ""
      this.ckdItem.salary = ""
      this.ckdItem.jtzz = ""
      this.ckdItem.diyu = ""
      this.ckdItem.didian = ""
      this.ckdItem.qudao = ""
      this.ckdItem.quxiang = ""
      this.ckdItem.enter_addr = ""
      this.ckdItem.school_name = ""
    },
    modify() {
      this.edit = true
      if (this.ckdItem.jyqx == '出国') {
        this.showEnter = true
      }
    },
    handleCancel() {
      this.edit = false
      if (this.ckdItem.school_name) {
        this.showEnter = false
      }
    }

  }

}

</script>

<style scoped>
.form-required:before {
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}
</style>